<template>
  <div class="text-container">
    Jede Apotheke hat ihren Wert, der sich aus anerkannten Rechenmodellen
    ableiten lässt. Wir ermitteln den Wert Ihrer Apotheke.
  </div>
</template>

<script>
export default {
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Wir ermitteln den Wert Ihrer Apotheke anhand anerkannten Rechenmodelle.",
      },
    ],
  },
};
</script>